// src/components/SignIn.js
import React, { useState, useContext, useEffect } from "react";
import { Col, Row, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import useAxiosWrapper from "../../api/axiosWrapper";
import { UtilContext } from "../../contexts/UtilContext";
import classes from "./SignIn.module.css";
import { FaAngleLeft } from "react-icons/fa6";
import { isMobile } from "react-device-detect";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const axiosInstance = useAxiosWrapper();
  const navigate = useNavigate();
  const { selectedCharity, setSelectedCharity, setUsername } =
    useContext(UtilContext);

  useEffect(() => {
    const userData = localStorage.getItem("user-ai");
    if (!!userData) {
      const res = JSON.parse(userData);
      if (res?.charity) {
        setSelectedCharity(res?.charity);
        navigate("/confirm-charity");
      } else {
        navigate("/home");
      }
    }
  }, []);

  const handleSignIn = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        process.env.REACT_APP_DO_GOOD_URL + "/user/login",
        {
          email,
          password,
        }
      );
      if (!!response?.data?.data?.id) {
        localStorage.setItem(
          "user-ai",
          JSON.stringify({
            name: response?.data?.data?.first_name,
            charity: selectedCharity,
          })
        );
        setUsername(response?.data?.data?.first_name);
        setMessage("Sign-in successful!");
        navigate("/home");
      } else {
        setMessage(response.message || "Sign-in failed.");
      }
      setLoading(false);
    } catch (error) {
      setMessage("Sign-in failed.");
      setLoading(false);
    }
  };

  return (
    <Row className="justify-content-center align-items-center h-100">
      <div onClick={() => navigate(-1)} className="back">
        <FaAngleLeft />
      </div>
      <Col className="">
        <div
          className="d-flex justify-content-center w-100 container"
          style={{ marginTop: isMobile ? 120 : 0 }}
        >
          <form onSubmit={handleSignIn} style={{ width: isMobile ? 320 : 360 }}>
            <h3>Sign In</h3>
            <div className="form-floating mb-3 mt-4">
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter Email"
              />
              <label htmlFor="email" className="form-label">
                Email
              </label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Enter password"
              />
              <label htmlFor="password" className="form-label">
                Password
              </label>
            </div>
            <div style={{ textAlign: "end", fontSize: 14 }} className="mb-2">
              Create a new account?
              <span
                onClick={() => navigate("/signup")}
                style={{
                  cursor: "pointer",
                  textDecorationLine: "underline",
                  color: "#ff5344",
                }}
              >
                {" "}
                Sign up.
              </span>
            </div>
            <button
              type="submit"
              className="btn btn-secondary"
              style={{ backgroundColor: "#ff5344", width: "100%" }}
            >
              Sign In
            </button>
            {loading && (
              <div className="d-flex justify-content-center mt-3">
                <Spinner />
              </div>
            )}
            <div
              style={{
                width: 360,
                textAlign: "center",

                justifyContent: "center",
              }}
            >
              {message && (
                <div className="alert alert-info mt-3">{message}</div>
              )}
            </div>
          </form>
        </div>
      </Col>
      <Col className={`d-none d-md-flex ${classes.imgCtr}`}>
        <img
          src={"confirm.png"}
          alt=""
          style={{ height: 260, width: 494, objectFit: "contain" }}
        />
      </Col>
    </Row>
  );
};

export default SignIn;
