import { Button, Col, Container, Row, Spinner } from "reactstrap";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Calender from "../../assests/CalandarBlack.png";
import Notification from "../../assests/bullhorn.svg";
import Chart from "../../assests/Chart-bar.svg";
import { FiRefreshCcw } from "react-icons/fi";
import { useContext, useEffect, useState } from "react";
import { UtilContext } from "../../contexts/UtilContext";
import classes from "./EmailTemplate.module.css";
import useAxiosWrapper from "../../api/axiosWrapper";

export default function EmailTemplate() {
  const navigate = useNavigate();
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [error, setError] = useState(null);
  const axiosInstance = useAxiosWrapper();
  const [loading, setLoading] = useState(false);
  const {
    selectedSuggestion,
    selectedTheme,
    selectedCharity,
    selectedIdea,
    username,
  } = useContext(UtilContext);

  useEffect(() => {
    if (!username) {
      navigate("/");
      return;
    }
    if (
      !selectedTheme ||
      !selectedCharity ||
      !selectedSuggestion ||
      !selectedIdea
    ) {
      navigate("/");
      return;
    }
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const url = process.env.REACT_APP_DO_GOOD_URL + "/campaign/email/template";
    const body = {
      event: selectedSuggestion,
      theme: selectedTheme,
      charity_id: selectedCharity?.id,
      idea: selectedIdea,
    };

    try {
      const response = await axiosInstance.post(url, body);
      console.log(response, "suajsm");

      const events = response?.data?.data?.email;
      setEmailTemplate(events);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Row className="justify-content-center align-items-center h-100">
        <div onClick={() => navigate(-1)} className="back">
          <FaAngleLeft />
        </div>
        {loading ? (
          <div style={{ marginTop: 100, textAlign: "center" }}>
            <Spinner />
          </div>
        ) : (
          <>
            <Row className="justify-content-center">
              <Col sm={12} md={10}>
                <div className={classes.heroImg}>
                  <img
                    src={emailTemplate?.image?.landscape}
                    alt=""
                    className={classes.img}
                  />
                </div>
              </Col>
              <Col xs={12} md={10} className={classes.ctr}>
                Subject Line: {emailTemplate?.title}
                <div className="mt-3">
                  {emailTemplate?.body?.split("\n").map(function (item) {
                    return (
                      <div>
                        {item}
                        <br />
                      </div>
                    );
                  })}
                </div>
                <button
                  className="btn btn-dark mt-4 "
                  onClick={() => {
                    navigate("/complete");
                  }}
                >
                  Download Email
                </button>
              </Col>
            </Row>
          </>
        )}
      </Row>
    </Container>
  );
}
