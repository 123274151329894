// src/components/SignUp.js
import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Spinner } from "reactstrap";
import useAxiosWrapper from "../../api/axiosWrapper";
import { useNavigate } from "react-router-dom";
import { UtilContext } from "../../contexts/UtilContext";
import classes from "./SignUp.module.css";
import { FaAngleLeft } from "react-icons/fa6";
import { isMobile } from "react-device-detect";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const axiosInstance = useAxiosWrapper();
  const [loading, setLoading] = useState(false);
  const { selectedCharity, setSelectedCharity, setUsername } =
    useContext(UtilContext);
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("user-ai");
    if (!!userData) {
      const res = JSON.parse(userData);
      if (res?.charity) {
        setSelectedCharity(res?.charity);
        navigate("/confirm-charity");
      } else {
        navigate("/home");
      }
    }
  }, []);

  const handleSignUp = async (event) => {
    setMessage("");
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        process.env.REACT_APP_DO_GOOD_URL + "/user/enroll",
        {
          email,
          password,
          first_name: firstName,
          last_name: lastName,
        }
      );
      if (response.status == 200) {
        setMessage("Sign-up successful!");
        setUsername(firstName);
        localStorage.setItem(
          "user-ai",
          JSON.stringify({ name: firstName, charity: selectedCharity })
        );
        setTimeout(() => {
          navigate("/home"); //need to change
        }, 800);
      } else {
        setMessage(response.message || "Sign-up failed.");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setMessage("Sign-up failed.");
      setLoading(false);
    }
  };

  return (
    <Row className="justify-content-center align-items-center h-100">
      <div onClick={() => navigate(-1)} className="back">
        <FaAngleLeft />
      </div>

      <Col className="">
        <div
          className="d-flex justify-content-center w-100 container"
          style={{ marginTop: isMobile ? 120 : 0 }}
        >
          <form onSubmit={handleSignUp} style={{ width: 400 }}>
            <h3>Sign Up</h3>
            <div className="form-floating mb-3 mt-4">
              <input
                type="text"
                className="form-control"
                id="name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                placeholder="First Name"
              />
              <label htmlFor="name" className="form-label">
                First Name
              </label>
            </div>
            <div className="form-floating mb-3 mt-4">
              <input
                type="text"
                className="form-control"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                placeholder="Last Name"
              />
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
            </div>
            <div className="form-floating mb-3 mt-4">
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Email"
              />
              <label htmlFor="email" className="form-label">
                Email
              </label>
            </div>
            <div className="form-floating mb-3 mt-4">
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Password"
              />
              <label htmlFor="password" className="form-label">
                Password
              </label>
            </div>
            <div style={{ textAlign: "end", fontSize: 14 }} className="mb-2">
              Already have an account?
              <span
                onClick={() => navigate("/signin")}
                style={{
                  cursor: "pointer",
                  textDecorationLine: "underline",
                  color: "#ff5344",
                }}
              >
                {" "}
                Log in.
              </span>
            </div>
            <button
              type="submit"
              className="btn btn-secondary"
              style={{ backgroundColor: "#ff5344", width: "100%" }}
            >
              Create Account
            </button>
          </form>
        </div>

        {loading && (
          <div className="d-flex justify-content-center mt-3">
            <Spinner />
          </div>
        )}
        {message && (
          <div className="d-flex justify-content-center">
            <div className="alert alert-info mt-3" style={{ width: 400 }}>
              {message}
            </div>
          </div>
        )}
      </Col>
      <Col className={`d-none d-md-flex ${classes.imgCtr}`}>
        <img
          src={"confirm.png"}
          alt=""
          style={{ height: 260, width: 494, objectFit: "contain" }}
        />
      </Col>
    </Row>
  );
};

export default SignUp;
